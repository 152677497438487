
// (C) Copyright IBM Deutschland GmbH 2021.  All rights reserved.

/***********************************************************************************************
imports
***********************************************************************************************/

import { saveAs } from 'file-saver'
import { utils, write, WorkBook } from 'xlsx-js-style'
import { Injectable, NgZone } from '@angular/core'
import { MatSnackBar } from '@angular/material/snack-bar'
import { environment } from 'src/environments/environment'

/***********************************************************************************************
constants
***********************************************************************************************/

const SMALL_WIDTH_BREAKPOINT = 1023


/***********************************************************************************************
decorators
***********************************************************************************************/

@Injectable({
    providedIn: 'root'
})


/***********************************************************************************************
class
***********************************************************************************************/

export class GlobalUiService {

    // members
    /*-----------------------------------------------------------------------------------*/

    private _logOutputs: boolean
    private _mediaMatcher: MediaQueryList
    private _logComponents: boolean
    private _logConstructions: boolean

    // constructor
    /*-----------------------------------------------------------------------------------*/

    constructor(
        private _zone: NgZone,
        private _snackBar: MatSnackBar
    ) {
        this._init()
    }

    // private
    /*-----------------------------------------------------------------------------------*/
    
    /**
     * inits the class
     * @returns void
     */
    private _init(): void {
        
        // choose what outputs you like
        this._logOutputs = true
        this._logComponents = false
        this._logConstructions = false

        // sets the maxvalue for the media matcher
        this._mediaMatcher = matchMedia(`(max-width: ${SMALL_WIDTH_BREAKPOINT}px)`)

        // outputs the construction
        this.logMessage('service "global-ui" is constructed', 2)
    }

    // public
    /*-----------------------------------------------------------------------------------*/
    
    /**
     * tells us if the screen is smaller than SMALL_WIDTH_BREAKPOINT
     * @returns boolean
     */
    isScreenSmall(): boolean {
        
        return this._mediaMatcher.matches
    }

    /**
     * a more fancy console output
     * @param  {string} message
     * @param  {number} indent
     * @returns void
     */
    logMessage(message: string, indent: number): void {
        
        if (this._logOutputs) {
            if (message.indexOf('component') > -1 && !this._logComponents) return
            if (message.indexOf('constructed') > -1 && !this._logConstructions) return

            let prefix: string = ''
            for (let i = 0; i < indent; i++) prefix += '-'
            prefix += '>'

            message = message.toUpperCase()

            console.log(`${prefix} ${message}`)
        }
    }

    /**
     * copies a bunch of text into your clipboard
     * @param  {} answers
     */
    copySingleItemToClipboard = (answers:any) => {

        if(!Array.isArray(answers)) answers = [answers]

        let textArea = document.createElement('textarea')
        textArea.value = ''

        answers.forEach(answer => {
            let _answer = answer.valueString || answer.valueInteger || answer.valueDecimal || answer[0]?.lala || answer
            if(_answer.valueDate) _answer =( new Date(_answer.valueDate)).toLocaleString(environment.appConfig.localDateFormat, { year: 'numeric', month: '2-digit', day: '2-digit' })
            textArea.value = textArea.value + '\n' + _answer
        })

        textArea.value = textArea.value.trim()

        document.body.appendChild(textArea)
		textArea.select()
		document.execCommand('copy')
		document.body.removeChild(textArea)

		this._snackBar.open('Kopiert!', null, {
            duration: 500,
            panelClass: ['snack-bar-color-success'],
        })
	}

    /**
	 * @param  {string} date
	 * @returns string
	 */
	getShinyDate( date:string ):string {
		
		return (new Date(date)).toLocaleDateString(environment.appConfig.localDateFormat)
	}

    /**^
	 * @param  {string} date
	 * @returns string
	 */
	createExcelFile(res:any[], questionnaireVersion: string):void {

        const streamToArrayBuffer = stream => {
            const buffer = new ArrayBuffer(stream.length);
            const view = new Uint8Array(buffer);
            for (let i = 0; i !== stream.length; ++i) {
                view[i] = stream.charCodeAt(i) & 0xFF
            }
            return buffer
        }

        const createAnswerString = (answers) : string => {
            let answerString = ''
            if(answers?.length) answers.forEach(answer => {
                let _answer = answer.valueString || answer.valueInteger || answer.valueDecimal || answer
                if(_answer.valueDate) _answer =( new Date(_answer.valueDate)).toLocaleString(environment.appConfig.localDateFormat, { year: 'numeric', month: '2-digit', day: '2-digit' })
                answerString = (answerString + '\n' + _answer).trim()
            })
            return answerString  
        }

        const updateValueWithDetfaultHeaderStyle = (value='') => {
            return {v: value,   s: { fill: { fgColor: { rgb: "E9E9E9" } } , font: {bold: true} }}
        }

        const workSheetName = 'Export'
        const workBook: WorkBook = { SheetNames: [], Sheets: {} }

        const HEADER_ROW_1 = [updateValueWithDetfaultHeaderStyle(), updateValueWithDetfaultHeaderStyle()]
        const HEADER_ROW_2 = [updateValueWithDetfaultHeaderStyle(), updateValueWithDetfaultHeaderStyle()]
        const HEADER_ROW_3 = [updateValueWithDetfaultHeaderStyle('Patient ID'), updateValueWithDetfaultHeaderStyle('Created At')]
        const EXCEL_ROWS = [ HEADER_ROW_1, HEADER_ROW_2, HEADER_ROW_3 ]

        // adds the header rows
        if(res.length) {
            res[0].payload.forEach(item => {
                HEADER_ROW_1.push({v: item.linkId, s: { fill: { fgColor: { rgb: "E9E9E9" } }, font: {bold: true} }})
                HEADER_ROW_2.push({v: item.prefix, s: { fill: { fgColor: { rgb: "E9E9E9" } }, font: {bold: true}  }})
                HEADER_ROW_3.push({v: item.text,   s: { fill: { fgColor: { rgb: "E9E9E9" } } , font: {bold: true} }})
            })

            res.forEach((response) => {
                let ROW_ANSWERS = [response.patient_id, response.creation_date]
                response.payload.forEach(item => {
                    ROW_ANSWERS.push(createAnswerString(item.answer))
                })  
                EXCEL_ROWS.push(ROW_ANSWERS)  
            })
        }

        const workSheet = utils.aoa_to_sheet(EXCEL_ROWS)
        workBook.SheetNames.push(workSheetName)
        workBook.Sheets[workSheetName] = workSheet
        const workBookStream = write(workBook, { bookType: 'xlsx', bookSST: true, type: 'binary' })

        saveAs(new Blob([streamToArrayBuffer(workBookStream)], { type: 'application/octet-stream' }), 'Export_' + questionnaireVersion + '_' + (new Date()).toLocaleString() + '.xlsx')
	}
}
