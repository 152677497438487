/***********************************************************************************************
decorators
***********************************************************************************************/

import { Component } from '@angular/core'


/***********************************************************************************************
decorators
***********************************************************************************************/

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})


/***********************************************************************************************
class 
***********************************************************************************************/

export class AppComponent {
    
    title = 'response-viewer'

    // constructor
    /*-----------------------------------------------------------------------------------*/

    constructor() { 

        window.addEventListener('beforeinstallprompt', event => {
        })
    }
}
