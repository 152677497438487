
// (C) Copyright IBM Deutschland GmbH 2021.  All rights reserved.

/***********************************************************************************************
imports
***********************************************************************************************/

import { Component } from '@angular/core'


/***********************************************************************************************
decorators
***********************************************************************************************/

@Component({
    selector: 'app-not-found',
    templateUrl: './not-found.component.html',
    styleUrls: ['./not-found.component.scss']
})


/***********************************************************************************************
class 
***********************************************************************************************/

export class NotFoundComponent {}
