
// (C) Copyright IBM Deutschland GmbH 2021.  All rights reserved.

/***********************************************************************************************
imports
***********************************************************************************************/

import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { GlobalUiService } from './global-ui.service'


/***********************************************************************************************
decorators
***********************************************************************************************/

@Injectable({
    providedIn: 'root'
})


/***********************************************************************************************
class 
***********************************************************************************************/

export class TranslateService {

    // members
    /*-----------------------------------------------------------------------------------*/

    current: string    // currently selected language sting
    default: string    // default language sting
    data: any = {}     // loaded strings

    // constructor
    /*-----------------------------------------------------------------------------------*/

    constructor(
        private _ui: GlobalUiService,
        private _http: HttpClient
    ) {
       
        // loggs the construction of the class
        _ui.logMessage('service "translate" is constructed', 2)
    }

    // public
    /*-----------------------------------------------------------------------------------*/
    
    /**
     * sets the language string, which in turn determines the used files
     * @param  {string} lang
     * @returns Promise
     */
    use(lang: string): Promise<{}> {

        this.current = lang

        return new Promise((resolve, reject) => {
            
            const langPath = `assets/i18n/${lang || this.default}.json`
            
            this._http.get(langPath).subscribe(
                translation => {
                    this.data = Object.assign({}, translation || {})
                    resolve(this.data)
                },
                error => {
                    this.data = {}
                    resolve(this.data)
                }
            )
        })
    }
}
