// This file will be used during build for the test system in the cluster

export const environment = {

    // should thiis produce a production build?
	production: false,

    // is this build used in a staging environment?
	staging: true,

    // http endoints
	http: {
        // the base-uri of the backend (most of the tinme just '/api')
		baseUri: '/api',

        // the URL of the user-app
		appUri: 'https://urtikaria-sprechstunde-test.ghh4.unimedizin-mainz.de',

        // the api-endpints
		endpoints: {
			blockSlot: '/appointment/blockslot?date=',
			preChecked: '/appointment/prechecked',
			response: '/questionnaire/response?patientId=',
			dashboard: '/appointment/dashboardData',
			appointmentDetails: '/appointment/appointmentDetailsForSlot',
			calendarConfig: '/schedule/config?patientId=',
			availableSlots: '/appointment/availableSlots?date=',
			appointment: '/appointment',
			adminConfig: '/schedule/admin-config',
			createSlots: '/schedule/createSlots',
			excelExport: '/questionnaire/excel-export-data'
		}
	},

	appConfig: {
		appStrings: {
            // if a qr-code reader is used, this is the app-id value the code must hold in order do be readable
			qrCodeAppId: 'DERMA'
		},
		localDateFormat: 'de-DE',

        // the autofetch interval for the scheduler (in minutes)
		autoFetchScheduleConfigIntervalInMinutes: 10,

        // if a linkId starts with this string, it will be ignored in the rendering (like '0,')
		coreDataCategoryString: '9.'
	},

    // keycloak data 
	keycloak: {
		realm: 'derma-test',
		uri: 'https://derma-sso.ghh4.unimedizin-mainz.de',
		client_id: 'derma-viewer'
	}
}
