
// (C) Copyright IBM Deutschland GmbH 2021.  All rights reserved.

/***********************************************************************************************
imports
***********************************************************************************************/

import { NgModule } from '@angular/core'
import { Routes, RouterModule } from '@angular/router'
import { NotFoundComponent } from './not-found/not-found.component'


/***********************************************************************************************
constants
***********************************************************************************************/

const routes: Routes = [
    { path: 'login', loadChildren: () => import('./login/login.module').then(m => m.LoginModule) },
    { path: '', loadChildren: () => import('./main-app/main-app.module').then(m => m.MainAppModule) },
    { path: '**', component: NotFoundComponent }
]


/***********************************************************************************************
decorators
***********************************************************************************************/

@NgModule({
    imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
    exports: [RouterModule]
})


/***********************************************************************************************
class 
***********************************************************************************************/

export class AppRoutingModule { }
