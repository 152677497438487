
// (C) Copyright IBM Deutschland GmbH 2021.  All rights reserved.

/***********************************************************************************************
imports
***********************************************************************************************/

import { MatDialogModule } from '@angular/material/dialog'
import { BrowserModule } from '@angular/platform-browser'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { NgModule, APP_INITIALIZER } from '@angular/core'
import { HttpClientModule } from '@angular/common/http'

import { AppRoutingModule } from './app-routing.module'
import { AppComponent } from './app.component'
import { TranslateService } from './core/translate.service'
import { FormsModule } from '@angular/forms'
import { NotFoundComponent } from './not-found/not-found.component'
import { MatSnackBarModule } from '@angular/material/snack-bar'
import { TranslatePipe } from './shared/pipes/translate.pipe'
import { ServiceWorkerModule } from '@angular/service-worker'
import { environment } from '../environments/environment'
import { KeycloakAngularModule, KeycloakService } from "keycloak-angular"
import { keycloakInit } from './keycloak.init'


/***********************************************************************************************
factories
***********************************************************************************************/

/**
 * @param  {TranslateService} service
 */
const setupTranslateFactory = (service: TranslateService) : Function => () => service.use('de')


/***********************************************************************************************
decorators
***********************************************************************************************/

@NgModule({
    declarations: [
        AppComponent,
        NotFoundComponent
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        AppRoutingModule,
        FormsModule,
        HttpClientModule,
        MatSnackBarModule,
        MatDialogModule,
        ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
        KeycloakAngularModule,
    ],
    providers: [
        TranslatePipe,
        TranslateService,
        {
            provide: APP_INITIALIZER,
            useFactory: setupTranslateFactory,
            deps: [TranslateService],
            multi: true
        },
        {
            provide: APP_INITIALIZER,
            useFactory: keycloakInit,
            multi: true,
            deps: [KeycloakService],
        }
    ],
    bootstrap: [AppComponent]
})

/***********************************************************************************************
class 
***********************************************************************************************/

export class AppModule { }
