
// (C) Copyright IBM Deutschland GmbH 2021.  All rights reserved.

/***********************************************************************************************
imports
***********************************************************************************************/


import { Pipe, PipeTransform } from '@angular/core'
import { TranslateService } from 'src/app/core/translate.service'


/***********************************************************************************************
decorators
***********************************************************************************************/

@Pipe({
    name: 'translate',
    pure: false
})


/***********************************************************************************************
class 
***********************************************************************************************/

export class TranslatePipe implements PipeTransform {

    // constructor
    /*-----------------------------------------------------------------------------------*/

    constructor(private _translate: TranslateService) { }

    // public
    /*-----------------------------------------------------------------------------------*/
    
    /**
     * translates a screen using its key
     * @param  {any} string
     * @returns any
     */
    transform(key: string): string {
        
        return this._translate.data[key] || key
    }
}
